<template>
  <div>
    <div v-if="payload?.title" class="grid justify-center items-center mb-2 md:mb-10">
      <div class="text-base order-1 text-center" v-html="payload?.subTitle" v-if="payload?.subTitle"></div>
      <div class="text-2xl md:text-3xl order-1 text-center">
        {{payload?.title}}
      </div>
    </div>

    <div class="relative" :class="[payload.customClass]">
      <div
          ref="container"
          class="keen-slider"
      >
        <div
            :key="`styb-${idx}`"
            class="keen-slider__slide"
            v-for="(i, idx) in payload.content"
        >
          <NuxtLinkLocale :to="checkAndAddSlash(i.ctaLink)"
                    class="relative w-full overflow-hidden rounded-lg h-full block"
                    :class="i?.config?.isCtaShow ? 'cursor-default' : 'cursor-pointer'">
            <NuxtImg
                :src="getImageForDevice(i)"
                :alt="i.title"
                class="z-0 object-contain w-full"
                loading="lazy"
                fit="cover"
                format="webp"
                placeholder
                :quality="70"
                sizes="xs:300px md:512px lg:768px"
            />

            <div class="z-30 flex" :class="{'absolute inset-0' : !i.config?.titleIsOutbox}">
              <div
                  :class="[
                'w-full z-30 flex flex-col space-y-3 tracking-wider',
                horizontalClass(i.config.titleHPlacement, true),
                verticalClass(i.config.titleVPlacement, true),
                {[backdropClass] : payload.config?.text_backdrop_enable},
                i.config?.titleIsOutbox ? 'px-0.5 py-2.5' : 'text-white px-6 py-6'
              ]"
              >
                <div :class="titleClass" v-if="i.title">
                  {{ i.title }}
                </div>
                <div :class="subTitleClass" v-if="i.subTitle">
                  {{ i.subTitle }}
                </div>

                <div class="cta-button cursor-pointer" v-if="i.ctaText && i.config?.isCtaShow">
                  {{ i.ctaText }}
                </div>
              </div>
            </div>
          </NuxtLinkLocale>
        </div>
      </div>

      <UINavigationArrows :slider="slider" :current="current" v-if="props.payload.config?.show_navigation_arrows" />

    </div>
  </div>
</template>

<script setup>
const props = defineProps(['payload'])
const breakpoints = computed(() => {
  return {
    '(min-width: 768px)': {
      slides :  {
        perView: parseFloat(props.payload.config?.desktop_breakpoint) > 0 ? parseFloat(props.payload.config?.desktop_breakpoint) : 3,
        spacing: 16,
      }
    },
    '(min-width: 320px and max-width: 767px)': {
      slides :  {
        perView: parseFloat(props.payload.config?.tablet_breakpoint) > 0 ? parseFloat(props.payload.config?.tablet_breakpoint) : 2.5,
        spacing: 16,
      }
    },
    '(max-width: 767px)': {
      slides :  {
        perView: parseFloat(props.payload.config?.mobile_breakpoint) > 0 ? parseFloat(props.payload.config?.mobile_breakpoint) : 1.5,
        spacing: 16,
      }
    },
  }
});

const {autoPlayPlugin} = useSlider()
const current = ref(1)

const [container, slider] = useKeenSlider({
  initial: current.value,
  created: (s) => {
    current.value = s.track.details.rel
  },
  slideChanged: (s) => {
    current.value = s.track.details.rel
  },
  loop: false,
  breakpoints: breakpoints.value,
  slides: { perView: 5 },
}, [
  autoPlayPlugin
])

const backdropClass = computed(() => props.payload.config?.text_backdrop_class ? props.payload.config?.text_backdrop_class : 'bg-gradient-to-t from-black/40 via-transparent to-transparent')
const titleClass = computed(() => props.payload.config?.title_class ? props.payload.config?.title_class : 'md:text-4xl text-3xl leading-none')
const subTitleClass = computed(() => props.payload.config?.sub_title_class ? props.payload.config?.sub_title_class : 'text-primary/50 md:text-3xl text-2xl leading-none')

</script>